
import React from "react"

import PackageeditorPage from "../../components/negosyonow/controls/packageeditor";

const PackagetrackingPage = ({location}) => {

	return <PackageeditorPage
				location={location}
				mode={3} // Full
				/>

}

export default PackagetrackingPage;
